import * as React from "react"
import {useSpring} from 'react-spring'
import {AnimatedButtonProps} from 'src/components/AnimatedButton'
import ProductButton from 'src/components/ProductButton'
import LinksLayout from 'src/components/LinksLayout'
import {fadeInFromLeftConfig, fadeInFromRightConfig} from 'src/utils/animations'

const PC = (props) => {
  const fadeInFromLeft = useSpring(fadeInFromLeftConfig)
  const fadeInFromRight = useSpring(fadeInFromRightConfig)

  const getFluidImage = (imageName: string) => (
    props.data[imageName].childImageSharp.fluid
  )

  const productList: AnimatedButtonProps[] = [
    {
      backgroundImageData: getFluidImage('CPU'),
      link: 'https://amzn.to/3Fn00DD',
      fadeSpringClass: fadeInFromLeft,
      header: 'AMD Ryzen 9 5950x',
    },
    {
      backgroundImageData: getFluidImage('GPU'),
      link: 'https://amzn.to/3ym9kWU',
      fadeSpringClass: fadeInFromRight,
      header: 'Gigabyte RTX 3090 Vision OC',
    },
    {
      backgroundImageData: getFluidImage('RAM'),
      link: 'https://amzn.to/3MZlvNc',
      fadeSpringClass: fadeInFromLeft,
      header: 'Crucial Ballistics 3600MHz RGB 16GB x 4',
    },
    {
      backgroundImageData: getFluidImage('Case'),
      link: 'https://amzn.to/3kNzGZV',
      fadeSpringClass: fadeInFromRight,
      header: 'Lian Li 011D Mini',
    },
    {
      backgroundImageData: getFluidImage('AIO'),
      link: 'https://amzn.to/38Y15pd',
      fadeSpringClass: fadeInFromLeft,
      header: 'Lian Li Galahad 360',
    },
    {
      backgroundImageData: getFluidImage('Motherboard'),
      link: 'https://amzn.to/3FnWj0u',
      fadeSpringClass: fadeInFromRight,
      header: 'ASRock B550m Steel Legend',
    },
    {
      backgroundImageData: getFluidImage('SmallFan'),
      link: 'https://amzn.to/3MZxbje',
      fadeSpringClass: fadeInFromLeft,
      header: 'PCCooler Moonlight 120mm',
    },
    {
      backgroundImageData: getFluidImage('LargeFan'),
      link: 'https://amzn.to/3MSGxx0',
      fadeSpringClass: fadeInFromRight,
      header: 'PCCooler Moonlight 140mm',
    },
    {
      backgroundImageData: getFluidImage('PowerSupply'),
      link: 'https://amzn.to/3w8uMvP',
      fadeSpringClass: fadeInFromLeft,
      header: 'Corsair SF750',
    },
  ]

  return (
    <LinksLayout>
      {productList.map((linkData) => <ProductButton {...linkData}/> )}
    </LinksLayout>
  )}

export default PC

export const query = graphql`
  query {
    CPU: file(relativePath: { eq: "setup/pcbuild/cpu.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    GPU: file(relativePath: { eq: "setup/pcbuild/gpu.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    RAM: file(relativePath: { eq: "setup/pcbuild/ram.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Case: file(relativePath: { eq: "setup/pcbuild/case.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    AIO: file(relativePath: { eq: "setup/pcbuild/aio.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Motherboard: file(relativePath: { eq: "setup/pcbuild/motherboard.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    SmallFan: file(relativePath: { eq: "setup/pcbuild/120mm.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    LargeFan: file(relativePath: { eq: "setup/pcbuild/140mm.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    PowerSupply: file(relativePath: { eq: "setup/pcbuild/powersupply.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`